<template>
  <div>
    <v-card class="pr-5 mb-5 pb-3">
      <v-card-title class="d-flex">
        <h5>{{ sectionLabel }} No. {{ typeId }} Activities</h5>
      </v-card-title>

      <v-data-table
        item-key="id"
        class="Plans__clickable"
        show-expand
        :headers="headers"
        :items="auditLogs.audits"
        :loading="fetchAuditLogsLoading"
        :server-items-length="auditLogs.count"
        :footer-props="{
          showFirstLastPage: false,
          showCurrentPage: true,
          prevIcon: 'mdi-arrow-collapse-left',
          nextIcon: 'mdi-arrow-collapse-right',
          itemsPerPage,
          itemsPerPageOptions,
        }"
        @pagination="onPagination"
      >
        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ formatDate(item.createdAt) }}
          </div>
        </template>
        <template v-slot:item.changes="{ item }">
          <div>
            {{ JSON.stringify(item.changes) }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'InvoiceActivitiesTable',
  components: {},
  props: {
    patientId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20],
      fetchAuditLogsLoading: false,
      page: 1,
      auditLogs: {
        audits: [],
        count: 0,
      },
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    sectionLabel() {
      return this.type === 'invoice' ? 'Invoice' : 'Payment Plan';
    },
    headers() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Action',
          value: 'action',
          ...defaults,
        },
        {
          text: 'User',
          value: 'user_id',
          ...defaults,
        },
        {
          text: 'Changes',
          value: 'changes',
          ...defaults,
        },
        {
          text: 'Date',
          value: 'createdAt',
          ...defaults,
        },
      ];
    },
  },
  mounted() {
    this.fetchAuditLogs();
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('MMMM Do YYYY');
    },
    onPagination({ page, itemsPerPage }) {
      this.itemsPerPage = itemsPerPage;
      this.page = page;
      this.fetchAuditLogs();
    },
    async fetchAuditLogs() {
      try {
        this.fetchAuditLogsLoading = true;
        let customer_id = this.customer.customer_id;
        if (customer_id === 0) {
          customer_id = this.selectedCustomer.customer_id;
        }
        let url = `/proxy/invoice-audit-logs`;
        url += `?customer_id=${customer_id}&entityType=${this.type}`;
        url += `&patient_id=${this.patientId}&page=${this.page}&limit=${this.itemsPerPage}`;
        url += `&entityId=${this.typeId}`;

        const response = await window.axios.get(url);
        if (response && response.data.success) {
          this.auditLogs = response.data.result;
        } else {
          throw response.data;
        }
      } catch (error) {
        this.$store.dispatch('notifications/setError', error.message, {
          root: true,
        });
      } finally {
        this.fetchAuditLogsLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Plans {
  padding-top: 20px;

  &__filter-row {
    padding: 15px 0;
  }

  &__clickable {
    cursor: pointer;
  }
}
.emptybackground {
  background-color: white;
}
</style>
