var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"Plans__clickable",attrs:{"item-key":"id","show-expand":"","headers":_vm.headers,"items":_vm.messages,"loading":_vm.isLoading,"server-items-length":_vm.count,"footer-props":{
      showFirstLastPage: false,
      showCurrentPage: true,
      prevIcon: 'mdi-arrow-collapse-left',
      nextIcon: 'mdi-arrow-collapse-right',
      itemsPerPage: _vm.itemsPerPage,
      itemsPerPageOptions: _vm.itemsPerPageOptions,
    }},on:{"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.createdAt",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }