var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"pr-5 mb-5 pb-3"},[_c('v-card-title',{staticClass:"d-flex"},[_c('h5',[_vm._v(_vm._s(_vm.sectionLabel)+" No. "+_vm._s(_vm.typeId)+" Activities")])]),_c('v-data-table',{staticClass:"Plans__clickable",attrs:{"item-key":"id","show-expand":"","headers":_vm.headers,"items":_vm.auditLogs.audits,"loading":_vm.fetchAuditLogsLoading,"server-items-length":_vm.auditLogs.count,"footer-props":{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPage: _vm.itemsPerPage,
        itemsPerPageOptions: _vm.itemsPerPageOptions,
      }},on:{"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.createdAt",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.changes",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(JSON.stringify(item.changes))+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }